.container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.header {
    margin-top: 50px;
    color: #F23D3D;
}

